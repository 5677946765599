<template>
  <div>
    <footer class="footer" :class="this.footer == 'footer-dark' ? 'footer-dark' : ''">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 footer-copyright text-center">
            <p class="mb-0">&nbsp; <a href="#" target="_blank">{{this.currentLanguage ==='en'?'Organizer':'ผู้จัดกิจกรรม'}}</a> &nbsp;<a
                href="/find-order">{{this.currentLanguage ==='en'?'Find order':'ค้นหาใบสมัคร'}}</a> &nbsp; <a href="/">{{this.currentLanguage ==='en'?'Registration system':'ระบบรับสมัครงานวิ่ง'}}</a>&nbsp; <a href="/"> Copyright
                2024 © runrace.in.th</a> &nbsp; <a href="#"> </a> &nbsp; <a
                href="#">{{this.currentLanguage ==='en'?'Refund Policy':'นโยบายการคืนเงิน'}}</a>&nbsp; <a
                href="#">{{this.currentLanguage ==='en'?'Privacy Policy':'นโยบายความเป็นส่วนตัว'}}</a></p>
            <b>RUNRACE Co.,Ltd.</b>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'footerpage',
  data() {
    return {
      customizer: false,
      currentLanguage: 'th'

    };
  },
  mounted(){
    if(localStorage.getItem('currentLanguage') !== null){
      this.currentLanguage = localStorage.getItem('currentLanguage');
    }
  },
  computed: {
    ...mapGetters({
      footer: 'layout/footer'
    })
  }
};
</script>
