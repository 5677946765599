<template>
  <div class="row">&nbsp;</div>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="row">
          <welcomecard />
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="card">
      <div v-if="this.is_authorization === 'N'" class="card-body">
        <div class="d-flex justify-content-center">
          You do not have authorization for this page
        </div>
      </div>

      <div v-if="this.is_authorization=== 'Y'" class="card-body">

          <div class="card">
            <div class="card-header">
              <b>[ALL Order]</b>
              <div class="header-top">
                <h5 class="m-0"></h5>
                <form class="form-inline">
                  <div class="form-group">
                    <select class="form-select" v-model="order_filter_type">
                      <option value="mobile">หมายเลขโทรศัพท์</option>
                      <option value="payment_ref">เลขที่ใบเสร็จ</option>
                      <option value="uuid">เลขที่ใบสมัคร</option>
                      <option value="status">สถานะ</option>
                      <option value="email">อีเมลล์</option>
                      <option value="delivery_add_name">ชื่อผู้สมัคร</option>
                    </select>
                  </div>
                  &nbsp;
                  <div class="form-group">
                    <input
                      class="form-control"
                      v-model="order_filter_value"
                      type="text"
                      minlength="3"
                      placeholder="คำค้นหา"
                    />
                  </div>
                  &nbsp;&nbsp;
                  <button
                    type="submit"
                    @click.prevent="load_last_registration('')"
                    @submit.prevent="load_last_registration('')"
                    class="btn btn-primary"
                  >
                    <vue-feather type="filter" size="18"> </vue-feather>
                    ค้นหา</button
                  >&nbsp;&nbsp;
                  <button
                    type="reset"
                    @click.prevent="load_last_registration('INITIAL_HAVE_SLIP')"
                    class="btn btn-primary"
                  >
                    <vue-feather type="filter" size="18"> </vue-feather>
                    รอชำระ
                  </button>&nbsp;&nbsp;
                  <button
                    type="reset"
                    @click.prevent="clearLatestOrder()"
                    class="btn btn-primary"
                  >
                    <vue-feather type="refresh-cw" size="18"> </vue-feather>
                    ทั้งหมด
                  </button>
                </form>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th style="text-align: center">ใบสมัคร</th>
                    <th style="text-align: center">สถานะ</th>
                    <th style="text-align: center">วันที่สมัคร</th>
                    <th style="text-align: center">ใบเสร็จ</th>
                    <th style="text-align: center">รับเงินเข้าระบบ</th>
                    <th style="text-align: center">ยอด</th>
                    <th style="text-align: center">เวลา</th>
                    <th style="text-align: center">P_CC</th>
                    <th style="text-align: center">หมายเลขโทรศัพท์</th>
                    <th style="text-align: center">ชื่อผู้สมัคร</th>
                    <th style="text-align: left">อีเมลล์</th>
                    <th style="text-align: left">จำนวนผู้สมัคร</th>
                    <th style="text-align: left">การจัดส่ง</th>
                    <th style="text-align: left">แก้ไขข้อมูล</th>
                    <th style="text-align: left">กิจกรรม</th>
                  </tr>
                </thead>
                <tbody v-if="this.is_loading_last_registration === 'Y'">
                  <tr>
                    <td colspan="15">
                      <div class="d-flex justify-content-center">
                        <img style="align-items: center; width: 60px" src="../../assets/images/running_loading.svg" />
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-if="this.report_last_registration.length !== 0">
                  <tr v-for="item in this.report_last_registration" :key="item">
                    <td style="text-align: center"><a target="_blank" :href=" '/order/' + item.uuid"><u><b>{{ item.uuid }}</b></u></a></td>
                    <td v-if="item.status === 'INITIAL'">
                        <font style="color: red;">รอชำระเงิน</font>
                      </td>
                      <td v-if="item.status === 'PAID'">
                        <font style="color: green;"><vue-feather stroke="green" type="check-circle" size="20"> </vue-feather> สำเร็จ</font>
                      </td>
                    <td style="text-align: center">{{ item.create_date }}</td>
                    <td style="text-align: center"><img :src=item.payment_slip_url style="width: 100px;" ></td>
                    <td v-if="item.status === 'INITIAL'" style="text-align: center"><button style="padding: 5px;" type="button" @click.prevent="direct_notify(item.uuid, item.mobile,item.id_card)" class="btn btn-primary" >
                              <vue-feather type="check-circle"  size="18"> </vue-feather> รับเงิน </button></td>
                    <td v-if="item.status !== 'INITIAL'" style="text-align: center" ><vue-feather stroke="green" type="check-circle" size="20"> </vue-feather></td>                              
                    <td style="text-align: center">{{ item.amount }}</td>
                    <td style="text-align: center">{{ item.payment_slip_time }}</td>
                    <td style="text-align: center">{{ item.payment_method }}</td>
                    <td style="text-align: center">{{ item.mobile }}</td>
                    <td style="text-align: left">{{ item.delivery_add_name }}</td>
                    <td style="text-align: left">{{ item.email }}</td>
                    <td style="text-align: center">{{ item.runner_count }}</td>
                    <td style="text-align: left">{{ item.delivery_type_name }}</td>
                    <td style="text-align: left"><a target="_blank" :href=" '/order-update/' + item.event_key+'/'+item.uuid"><vue-feather type="edit" size="20"></vue-feather></a></td>
                    <td style="text-align: left"><a :href="'/dashboard-organizer/' + item.event_key">{{ item.event_key }}</a></td>
                  </tr>
                </tbody>
                <tbody>

                </tbody>
              </table>
            </div>
          </div>
 

        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <div class="header-top">
                <h5 class="m-0"><b>กิจกรรมของฉัน[ADMIN]</b></h5>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th style="text-align: left">ชื่อกิจกรรม</th>
                    <th style="text-align: left">วันที่จัดกิจกรรม</th>
                    <th style="text-align: left">วันที่เปิดระบสมัคร</th>
                    <th style="text-align: left">วันที่ปิดรับระบสมัคร</th>
                    <th style="text-align: left">รายงาน</th>
                  </tr>
                </thead>
                <tbody v-if="this.is_loading === 'Y'">
                  <tr>
                    <td colspan="10">
                      <div class="d-flex justify-content-center">
                        <img style="align-items: center; width: 60px" src="../../assets/images/running_loading.svg" />
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-if="this.data_my_order.length !== 0">
                  <tr v-for="item in this.data_my_order" :key="item">
                    <td style="text-align: left">{{ item.title }}</td>
                    <td style="text-align: left">{{ item.event_date_text }}</td>
                    <td style="text-align: left">{{ item.registration_start_text }}</td>
                    <td style="text-align: left">{{ item.registration_end_text }}</td>
                    <td style="text-align: left"><a :href="'/dashboard-organizer/' + item.event_key">รายงานผู้สมัคร</a></td>
                  </tr>
                </tbody>
                <tbody>

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import welcomecard from "./user_welcomecard.vue";
import axios from "axios";
export default {
  components: {
    welcomecard,
  },
  data() {
    return {
      is_authorization: null,
      token: null,
      is_loading: "N",
      data_my_order: [],
      order_filter_type: "mobile",
      order_filter_value: "",
      is_loading_last_registration: "N",
      report_last_registration: [],
    }
  },
  async mounted() {
    this.token = localStorage.getItem("_rr_token");
    if(this.token !== null){
      await this.authorization()
      if(this.is_authorization === 'Y'){
        this.load_my_order();
        this.clearLatestOrder();
      }
      
     
    }else{
      //alert("เซสชันหมดอายุ กรุณาเข้าสู่ระบบอีกครั้ง")
      this.$router.push("/auth/login");
    }
   
  },
  methods: {
    async direct_notify(_uuid,_mobile_no,_id_card){
      alert("Please login by event oganizer role: "+_uuid+", "+_mobile_no+", "+_id_card)
    },
    async authorization(_uuid,_mobile_no){
            const data = {
              event_key: 'all-admin'
            };
            const headers = {
              'Authorization': 'Bearer ' + this.token
            };
            const res = await axios.post("v1/organizer/authorization", data, { headers });
            if (res.data.status === 'success') {
              this.is_authorization = res.data.message.is_authorization
            } else {
              alert("พบข้อผิดพลาด: "+res.data.message	)
            }
     },
    async clearLatestOrder(_filter) {
      this.order_filter_value = "";
      
      this.load_last_registration('');
    },
    async load_last_registration(_filter) {

      if (this.order_filter_value.length == 0 || this.order_filter_value.length >= 3){
        this.is_loading_last_registration = "Y"
        this.report_last_registration = [];
        let data = {
          event_key: this.$route.params.event_key,
          order_filter_type: this.order_filter_type,
          order_filter_value: this.order_filter_value
        };
        if(_filter === 'INITIAL_HAVE_SLIP'){
          data = {
          event_key: this.$route.params.event_key,
          order_filter_type: "INITIAL_HAVE_SLIP",
          order_filter_value: "spacial"
        };
        }
        const headers = {
          'Authorization': 'Bearer ' + this.token
        };
        
        const res = await axios.post("v1/organizer/report-order-latest-admin", data, { headers });
        //console.log("res:", res)
        if (res.data.status === 'success') {
          this.report_last_registration = res.data.body
          this.is_loading_last_registration = "N"
        } else {

          alert("พบข้อผิดพลาด")
          this.is_loading_last_registration = "N"
        }
      }

      
    },
    async load_my_order() {
      this.is_loading = "Y"
      this.data_my_order = [];
      const headers = {
        'Authorization': 'Bearer ' + this.token
      };
      const res = await axios.post("v1/organizer/event", null, { headers });
      //console.log("res:", res)
      if (res.data.status === 'success') {
        this.data_my_order = res.data.body
        this.is_loading = "N"
      } else {

        alert("พบข้อผิดพลาด")
        this.is_loading = "N"
      }
    }
  }
};
</script>
