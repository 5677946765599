<template>
  <li class="profile-nav onhover-dropdown pe-0 py-0">
    <div class="media profile-media">
      <img class="b-r-10" style="height: 35px; width: 35px" src="@/assets/images/running/user-profile-icon.png" alt="" />
    </div>
    <ul class="profile-dropdown onhover-show-div">
      <li v-if="this.is_login === 'N'">
        <router-link :to="'/auth/login'"><vue-feather type="log-in"></vue-feather><span>เข้าสู่ระบบ</span></router-link>
      </li>
      <!-- <li v-if="this.is_login === 'Y'">
        <router-link :to="'/dashboard'"><vue-feather
            type="calendar"></vue-feather><span>ประวัติการสมัคร</span></router-link>
      </li> -->
      
      <li v-if="this.is_login === 'Y'">
        <router-link :to="'/profile'"><vue-feather type="user"></vue-feather><span>บัญชีผู้ใช้งาน</span></router-link>
      </li>
      <li v-if="this.is_login === 'Y'">
        <router-link :to="'/dashboard-organizer'"><vue-feather
            type="calendar"></vue-feather><span>ผู้จัดกิจกรรม</span></router-link>
      </li>
      <li v-if="this.is_login === 'Y' && this._rr_user_obj.role === 'ssadmin'">
        <router-link :to="'/dashboard-admin'"><vue-feather
            type="star"></vue-feather><span>ผู้ดูแลระบบ</span></router-link>
      </li>
      <li v-if="this.is_login === 'Y'">
        <a @click="logout"><vue-feather type="log-out"></vue-feather><span>ออกจากระบบ</span></a>
      </li>
    </ul>
  </li>
</template>

<script>
import axios from "axios";
export default {

  name: 'Profile',
  data() {

    return {
      currentLanguage: 'th',
      is_login: "N",
      _rr_user: null,
      _rr_user_obj: {},
      token: null
    };
  },
  
  async mounted(){
    this.token = localStorage.getItem("_rr_token");
    this._rr_user = localStorage.getItem("_rr_user");

    if(this.token !== null && this._rr_user !== null){
      await this.profile_checking()
      this._rr_user_obj = JSON.parse(this._rr_user)
    }
    
  },
  methods: {
    async logout() {
      localStorage.removeItem("_rr_token");
      localStorage.removeItem("_rr_user")
      location.reload();
      this.$router.push("/");

    }, async profile_checking() {
      if (localStorage.getItem("_rr_token") !== null) {
        const headers = {
          'Authorization': 'Bearer ' + localStorage.getItem("_rr_token")
        };
        const res = await axios.post("/v1/auth/profile", null, { headers });
        if (res.status === 201) {
          this.is_login = "Y"
        }
      }
    },
  },

};
</script>
