<template>
  <div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 p-0">
          <div class="login-card">
            <div>
              <div>
                <a class="logo">
                  <img width="300" class="img-fluid for-light" src="../../assets/images/logo/running-logo-login.png"
                    alt="looginpage" />
                  <img width="300" class="img-fluid for-dark" src="../../assets/images/logo/logo_dark.png"
                    alt="looginpage" />
                </a>
              </div>
              <div class="login-main">
                <form class="theme-form" @submit.prevent="login">
                  <h4>{{ this.currentLanguage === 'en' ? "Sign in to account" : "เข้าสู่ระบบ" }}</h4>
                  <p>{{ this.currentLanguage === 'en' ? "Enter your email & password to login" :
                  "ป้อนข้อมูลอีเมลล์และรหัสผ่าน" }}</p>
                  <div class="form-group">
                    <label class="col-form-label">Email Address</label>
                    <input class="form-control" type="email" required placeholder="your@gmail.com" v-model="email" />

                  </div>
                  <div class="form-group">
                    <label class="col-form-label">Password</label>
                    <div class="form-input position-relative">
                      <input class="form-control" type="password" required v-model="password" />
                    </div>
                  </div>
                  <div class="form-group mb-0">
                    <div class="checkbox p-0">
                      <input id="checkbox1" type="checkbox" />
                      <label class="text-muted" for="checkbox1">Remember password</label>
                    </div>
                    <!-- <router-link class="link" to="/authentication/forgetpassword"> Forgot password?</router-link> -->
                    <div class="text-end mt-3" >
                      <!-- <button v-if="this.is_login === 'N'" class="btn btn-primary btn-block w-100" type="submit">
                        เข้าสู่ระบบ
                      </button> -->
                      <button  class="btn btn-primary btn-block w-100" type="submit" :disabled="this.is_login === 'Y' ? '' : disabled">
                        <vue-feather v-if="this.is_login === 'Y'" type="loader" size="18" animation="spin"></vue-feather> เข้าสู่ระบบ
                      </button>
                    </div>
                  </div>
                  <h6 class="text-muted mt-4"></h6>

                  <!-- <p class="mt-4 mb-0 text-center">
                    Don't have account?<a class="ms-2" href="/auth/register">Create Account</a>
                  </p> -->
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.validate-error {
  color: red;
}
</style>

<script>
import axios from "axios";

export default {
  name: "login",
  setup() {

  },
  data() {
    return {
      currentLanguage: 'th',
      user: {},
      email: null,
      password: null,
      is_login: "N"
    };
  },

  async created() {
    this.currentLanguage = localStorage.getItem('currentLanguage');
    

  },
  async mounted(){
    await this.profile_checking()
  },

  methods: {

    async profile_checking() {
      if(localStorage.getItem("_rr_token") !== null){
        if (localStorage.getItem("_rr_token").length > 10) {
        console.log(localStorage.getItem("_rr_token").length)
        const headers = {
          'Authorization': 'Bearer ' + localStorage.getItem("_rr_token")
        };
        const res = await axios.post("/v1/auth/profile", null, { headers });
        if (res.status === 201) {
          this.$router.push("/dashboard");
        }
      }
      }

    },
    async login() {
      this.is_login = "Y"
      try {
        let data = {
          email: this.email,
          password: this.password,
        }

        const response = await axios.post("/v1/auth/login", data);
        //console.log("response", response)
        if (response.data.status === "success") {
          localStorage.setItem("_rr_token", response.data.accessToken);
          localStorage.setItem("_rr_user", JSON.stringify(response.data.body));
          this.is_login = "N";
          //this.$router.push("/dashboard");
          //console.log("response.data.body"+response.data.body.role)
          if(response.data.body.role === 'organizer'){
              this.is_login = "N";
              window.location ="/dashboard-organizer"
          }else if(response.data.body.role === 'ssadmin'){
              this.is_login = "N";
              window.location ="/dashboard-admin"
          }else{
              this.is_login = "N";
              window.location ="/dashboard"
          }
          //
        }else{
          alert("พบข้อผิดพลาด! กรุณา login อีกครั้ง");
          window.location.reload()
        }
      } catch (error) {
        alert("พบข้อผิดพลาด! กรุณา login อีกครั้ง");
        this.$router.push({ path: "/auth/login" });
      }
    }
  }
};


</script>
